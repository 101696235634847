import React from 'react';
import './App.css';
import GridView from '../GridView';
// Location 7
// import { withAuthenticator } from 'aws-amplify-react';
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";


import "@aws-amplify/ui-react/styles.css";
import Typography from '@mui/material/Typography'

// Location 4
import Amplify, { Auth, API, graphqlOperation, Storage } from "aws-amplify";
import awsvideoconfig from "../../aws-video-exports";
import { createVodAsset, createVideoObject } from "../../graphql/mutations";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      titleVal: "",
      descVal: "",
      groups: [],
      progress: 0,
    };
    this.createWatchPanel = this.createWatchPanel.bind(this);
  }

  componentDidMount() {
    // Location 5
    const region = Amplify._config.aws_project_region;
    Auth.currentSession().then((data) => {
      const groups = data.idToken.payload["cognito:groups"];
      if (groups) {
        this.setState({ groups: data.idToken.payload["cognito:groups"] });
      }
    });

    Storage.configure({
      AWSS3: {
        bucket: awsvideoconfig.awsInputVideo,
        region,
        customPrefix: {
          public: "",
        },
      },
    });
  }
  

  createWatchPanel() {
    const { groups, titleVal, descVal, progress } = this.state;
    // let { id } = useParams();
    const id = this.props.match.params.id;
    if (groups.includes("Admin") || groups.includes("Viewer")) {
      return (
        <div className="App">
          <header className="App-header">
            <GridView />
            <div style={{marginBottom: "2em"}} />
              <button onClick={this.props.signOut}>Sign out</button>
            <div style={{marginBottom: "2em"}} />
          </header>
        </div>
      );
    }
    return (<div>
      You do not (yet) have permission to view this page.<br />
      <div style={{marginBottom: "2em"}} />
        <button onClick={this.props.signOut}>Sign out</button>
      <div style={{marginBottom: "2em"}} />
    </div>);
  }

  render() {
    return <div>{this.createWatchPanel()}</div>;
  }
}
// Location 3
export default withAuthenticator(App, true);
