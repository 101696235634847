import React from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import App from '../App';
import Home from '../Home';
import Admin from '../Admin';
import Watch from '../Watch';

const Routing = (
  <Router>
    <div>
      <Route exact path="/" component={Home} />
      <Route path="/Admin" component={Admin} />
      <Route path="/Browse" component={App} />
      <Route path="/video/:id" component={Watch} />
    </div>
  </Router>
);

export default Routing;
