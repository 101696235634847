import React from 'react'

import { Container, Grid } from "@mui/material";

function Home() {
  return (
    <div>
        <div style={{ marginTop: "50px" }} />
        <Container>
            <Grid justifyContent={"center"} alignItems={"center"} alignContent={"center"} textAlign={"center"} alignSelf={"center"}>
                <Grid item sx={12}>
                    <h1>If you're here, ask oddpawsx for the key.</h1>
                </Grid>
            </Grid>
        </Container>
    </div>
  )
}

export default Home