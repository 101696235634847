/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:915ca30e-c301-456e-8547-837b1a584605",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_DWrG6OWFC",
    "aws_user_pools_web_client_id": "1m20a7sonvfjmu9idjk8h3fd34",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://bw3bamdxnnembmapyghrucqj6e.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_mobile_analytics_app_id": "dfd0c435266c4c7faaf3c9e902db19f9",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
