/* eslint-disable import/order */
import React, { Component } from "react";
import { Grid, Col, Row } from "react-styled-flexboxgrid-v2";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import "./index.css";
import VideoPlayer from "../VideoPlayer";
import GridCardView from "../GridCardView";
import "video.js/dist/video-js.css";
// Location 9
import Amplify, { API, graphqlOperation, Analytics } from "aws-amplify";
import * as queries from "../../graphql/queries";

// Location 12
import awsvideo from "../../aws-video-exports";

import { Button } from "@mui/material";

// Location 14
import { onCreateVideoObject } from "../../graphql/subscriptions";

const analyticsConfig = {
    AWSPinpoint: {
          // Amazon Pinpoint App Client ID
          appId: '7b65997e03624b3e8c6153cb2597dd93',
          // Amazon service region
          region: 'us-east-1',
          mandatorySignIn: false,
    }
  }
  
Analytics.configure(analyticsConfig)

class WatchPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayingMovie: false,
      choosenItem: {},
      nextToken: "",
      sources: [],
      items: [],
    };

    this.handleOnDocumentBottom = this.handleOnDocumentBottom.bind(this);
  }

  async componentDidMount() {
    // Location 10
    const assets = await API.graphql(graphqlOperation(queries.listVodAssets));
    let { nextToken } = assets.data.listVodAssets;
    if (nextToken === undefined) {
      nextToken = "";
    }
    this.setState({ items: assets.data.listVodAssets.items, nextToken });

    // Location 16
    this.listenForNewAssets();
  }

  hideMovie = () => {
    this.setState({
      displayingMovie: false,
    });
  };

  displayMovie = (item) => {
    // Location 13
    // eslint-disable-next-line no-underscore-dangle
    const region = Amplify._config.aws_project_region;
    this.setState({
      sources: [
        {
          src: `https://${awsvideo.awsOutputVideo}/${item.video.id}/${item.video.id}.m3u8`,
          type: "application/x-mpegURL",
        },
      ],
      displayingMovie: true,
      choosenItem: item,
    });
    Analytics.record({ name: `videoView`, attributes: { videoId: item.id, videoTitle: item.title } });
  };

  overlayMovie = () => {
    const {
      displayingMovie,
      sources,
      choosenItem: { title, description },
    } = this.state;
    return (
      <Modal
        id="popup"
        style={{ maxWidth: 755 }}
        isOpen={displayingMovie}
        toggle={this.hideMovie}
      >
        <ModalHeader toggle={this.hideMovie}>{title}</ModalHeader>
        <ModalBody>
          {description ? description.split("\n").map((item, key) => {
            return <div key={key}>{item}</div>
          }): ""}
          <div style={{ marginBottom: "1em" }} />
          <VideoPlayer
            controls
            sources={sources}
            width={"300%"}
            height={420}
            bigPlayButton={false}
            autoplay
          />
        </ModalBody>
      </Modal>
    );
  };

  listenForNewAssets = () => {
    // Location 15
    API.graphql(graphqlOperation(onCreateVideoObject)).subscribe({
      next: (data) => {
        const { items } = this.state;
        items.push(data.value.data.onCreateVideoObject);
        this.setState({
          items,
        });
      },
    });
  };

  async handleOnDocumentBottom() {
    // Location 11
    const { nextToken, items } = this.state;
    if (nextToken !== "" && nextToken !== null && nextToken !== undefined) {
      console.log(nextToken);
      const assets = await API.graphql(
        graphqlOperation(queries.listVodAssets, { nextToken })
      );
      const newItems = items.concat(assets.data.listVodAssets.items);
      let newNextToken = assets.data.listVodAssets.nextToken;
      if (newNextToken === undefined) {
        newNextToken = "";
      }
      this.setState({ items: newItems, nextToken: newNextToken });
    }
  }

  render() {
    const { items } = this.state;
    const thisPageItems = items.filter((item) => item.id === this.props.thisPageId);
    // const itemHTML = items.map((item) => (
    const itemHTML = thisPageItems.length === 0 ? "Not found" : thisPageItems.map((item) => (
      <Col
        xs={6}
        sm={4}
        lg={3.5}
        style={{ paddingTop: 15, paddingBottom: 15 }}
        key={item.id}
      >
          {/* <h2>{JSON.stringify(item)}</h2> */}
        <button
          type="button"
          onClick={(e) => this.displayMovie(item, e)}
          aria-label={item.title}
        >
          {/* <GridCardView item={item} /> */}
            <Button>Play Video {JSON.stringify(item.title)}</Button>
        </button>
      </Col>
    ));

    return (
      <div style={{ paddingTop: 85 }}>
        {this.overlayMovie()}
        <BottomScrollListener onBottom={this.handleOnDocumentBottom} />
        <Grid fluid>
          <Row>{itemHTML}</Row>
        </Grid>
      </div>
    );
  }
}

export default WatchPage;
